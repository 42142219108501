<template>
  <div class="margin">
    <div class="title-section">
      <h1 class="title">
        Margin
        <span class="text-muted">(spent Monetization)</span>
      </h1>

      <div class="margin-filters-group">
        <div class="show-ads">
          <b-field>
            <b-switch v-model="showAds">
              Show ads
            </b-switch>
          </b-field>
        </div>

        <div class="margin-filters">
          <export :grid-api="api || undefined" />
        </div>
      </div>
    </div>

    <filters :variant-options="variantOptions" />

    <section>
      <grid
        ref="marginGrid"
        :columns="columns"
        :data="margins"
        :loading="$apollo.loading"
        :auto-group-column="autoGroupColumnDef"
        @ready="api = $event"
      />
    </section>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import moment from 'moment';
import countries from '../data/countries';
import { FILTERS } from '../plugins/filters';
import { GET_MARGINS } from '../gql-requests';
import GridMixin from '../mixins/GridMixin';
import Export from '../components/margin/Export';

export default {
  components: { Export },
  mixins: [GridMixin],
  data() {
    return {
      appNames: {},
      marginsByApp: [],
      marginsObject: {},
      countries,
      api: null,
      showAds: false,
      variantOptions: [
        {
          key: 'country',
          name: 'Country',
        },
        {
          key: 'lunigroup',
          name: 'Luni Group',
        },
      ],

    };
  },
  computed: {
    ...mapGetters('filters', ['getDateRange', 'getGranularity', 'getVariant']),
    autoGroupColumnDef() {
      return {
        headerName: `App > ${this.getVariant && this.getVariant[0].toUpperCase() + this.getVariant.slice(1)}`,
        minWidth: 300,
        field: this.isVariantProduct ? 'productName' : 'country',
        pinned: 'left',
      };
    },
    isVariantCountry() {
      return this.getVariant === 'country';
    },
    isVariantProduct() {
      return this.getVariant === 'product';
    },
    isVariantLuniGroup() {
      return this.getVariant === 'lunigroup';
    },
    timePeriods() {
      const timePeriods = [];
      const timePeriod = moment(this.getDateRange[0]);

      if (this.getGranularity === 'weekly') {
        timePeriod.date(1).day(8);
        if (timePeriod.date() > 7) {
          timePeriod.day(-6);
        }
      }

      const end = moment(this.getDateRange[1]).endOf('month');
      const fieldFormat = this.getGranularity === 'weekly' ? 'YYYYWW' : 'YYYYMM';
      const labelFormat = this.getGranularity === 'weekly' ? 'DD MMM YYYY' : 'MMMM YYYY';

      while (timePeriod.isSameOrBefore(end)) {
        timePeriods.push({
          field: timePeriod.format(fieldFormat),
          label: timePeriod.format(labelFormat),
        });
        const period = this.getGranularity === 'weekly' ? 'week' : 'month';
        timePeriod.add(1, period);
      }
      return timePeriods;
    },
    columns() {
      const columns = [{
        field: 'appName',
        colId: 0,
        headerName: 'App',
        rowGroup: true,
        hide: true,
      }];

      if (this.isVariantProduct) {
        columns.push({
          field: 'productName',
          colId: 1,
          headerName: 'Product',
          filter: true,
          sortable: true,
          pinned: 'left',
          maxWidth: 150,
          filterParams: {
            buttons: ['reset', 'apply'],
            debounceMs: 200,
          },
          menuTabs: ['generalMenuTab', 'filterMenuTab'],
          hide: true,
        });
      } else {
        columns.push({
          field: 'country',
          colId: 1,
          headerName: 'Country',
          filter: true,
          sortable: true,
          pinned: 'left',
          filterParams: {
            buttons: ['reset', 'apply'],
            debounceMs: 200,
          },
          maxWidth: 150,
          menuTabs: ['generalMenuTab', 'filterMenuTab'],
          hide: true,
        });
      }

      this.timePeriods.forEach((period) => {
        columns.push({
          field: period.field,
          colId: Number(period.field),
          headerName: period.label,
          sortable: true,
          valueGetter: (params) => {
            const currentAmount = params.data ? params.data[params.colDef.field] : null;
            const previousAmount = params.data ? params.data[Number(params.colDef.field) - 1] : null;
            const growth = previousAmount ? (currentAmount - previousAmount) / previousAmount : undefined;
            return { amount: currentAmount, previousAmount, growth };
          },
          aggFunc: this.mySum,
          filterParams: {
            buttons: ['reset', 'apply'],
            debounceMs: 200,
          },
          maxWidth: 170,
          menuTabs: ['generalMenuTab'],
          cellRendererFramework: 'AmountWithGrowth',
        });
      });

      return columns;
    },
    margins() {
      const margins = [];
      Object.keys(this.marginsObject).forEach((appId) => {
        Object.keys(this.marginsObject[appId]).forEach((dim) => {
          const row = {
            appName: this.appNames[appId],
          };

          if (this.isVariantProduct) {
            row.productName = dim;
          } else {
            row.country = dim;
          }

          const total = 0;
          this.timePeriods.forEach((period) => {
            if (this.marginsObject[appId][dim][period.field] === undefined) {
              row[period.field] = undefined;
            }
            const present = this.marginsObject[appId][dim][period.field];
            row[period.field] = (present?.amount + (this.showAds ? present?.ads : 0)) || undefined;
          });
          row.total = total;

          margins.push(row);
        });
      });

      return margins;
    },
  },
  watch: {
    marginsByApp() {
      this.formatMarginsAgGrid();
    },
  },
  methods: {
    withLeadingZero(number) {
      return `0${number}`.slice(-2);
    },
    totalFormatter(params) {
      return `${FILTERS.formatNumber(params.value)} €`;
    },
    mySum(params) {
      let sum = 0;
      let sumPast = 0;
      params.values.forEach((value) => {
        sum += value.amount || 0;
        sumPast += value.previousAmount || 0;
      });
      const growth = sumPast ? (sum - sumPast) / sumPast : undefined;
      return { amount: sum, growth };
    },
    formatMarginsAgGrid() {
      this.marginsObject = {};

      this.marginsByApp.forEach((raw) => {
        const timePeriod = this.getGranularity === 'weekly'
          ? `${raw.year}${this.withLeadingZero(raw.weekOfYear)}`
          : `${raw.year}${this.withLeadingZero(raw.month)}`;
        if (this.marginsObject[raw.appId] === undefined) {
          this.marginsObject[raw.appId] = {};
        }

        if (this.marginsObject[raw.appId][raw.country] === undefined) {
          this.marginsObject[raw.appId][raw.country] = {};
        }
        this.marginsObject[raw.appId][raw.country][timePeriod] = { amount: raw.proceeds - raw.spent, ads: raw.ads };

        if (this.appNames[raw.appId] === undefined) {
          this.appNames[raw.appId] = raw.appName;
        }
      });
    },
  },
  apollo: {
    marginsByApp: {
      query: GET_MARGINS,
      skip() {
        return !this.getDateRange.length;
      },
      variables() {
        const variables = {
          weekly: this.getGranularity === 'weekly',
          variant: this.getVariant,
          isVariantCountry: this.isVariantCountry || this.isVariantLuniGroup,
          isVariantProduct: this.isVariantProduct,
        };
        if (this.getDateRange.length) {
          const from = new Date(this.getDateRange[0]);
          const to = new Date(this.getDateRange[1]);
          variables.from = `${from.getFullYear()}-${from.getMonth() + 1}`;
          variables.to = `${to.getFullYear()}-${to.getMonth() + 1}`;
        }
        return variables;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.margin-filters-group {
  display: flex;
  align-items: center;

  & > * {
    margin-left: 10px;
  }
}
</style>
